<template>
  <div>
    <s-search :queryItems="queryItems" @fetchData="fetchUsers"></s-search>
    <div class="table-button left">
      <a-button
        type="plain"
        icon="plus"
        @click="openSubpage('add', '添加')"
        v-if="isShowBtns.indexOf('UserList-/api/user/adduser') > -1"
        >添加用户</a-button
      >
      <a-button @click="refresh">刷新</a-button>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="u_id"
      :columns="columns"
      :data="loadData"
      :showPagination="true"
      :pagination="true"
    >
      <span slot="action" slot-scope="text, record">
        <a
          class="text-btn"
          @click="openSubpage('view', '查看', record)"
          v-if="isShowBtns.indexOf('UserList-/api/user/userdetails') > -1"
          >查看</a
        >
        <a
          class="text-btn"
          @click="openSubpage('edit', '编辑', record)"
          v-if="isShowBtns.indexOf('UserList-/api/user/edituser') > -1"
          >编辑</a
        >
        <a
          class="text-btn danger"
          @click="handleDelete(record)"
          v-if="isShowBtns.indexOf('UserList-/api/user/deluser') > -1"
          >删除</a
        >
      </span>
    </s-table>
    <div style="height: 16px"></div>
    <a-drawer
      :title="title"
      placement="right"
      :width="750"
      :visible="visible"
      @close="onClose"
      :destroyOnClose="true"
    >
      <user-view
        :userdata="userdata"
        v-if="childPage === 'view'"
        @onClose="onClose"
        ref="RoleView"
      ></user-view>
      <user-edit
        :userdata="userdata"
        v-if="childPage === 'edit' || childPage === 'add'"
        :childPage="childPage"
        :type="childPage"
        @onClose="onClose"
        ref="RoleView"
      ></user-edit>
    </a-drawer>
    <!-- 删除 -->
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleDel"
      :confirm-loading="confirmDelLoading"
      @ok="deleteOk"
      @cancel="deleteCancel"
    >
      <p>确定删除{{ UserName }}？</p>
    </a-modal>
  </div>
</template>

<script>
import { STable, SSearch } from "@/components";
import { GetUsers, GetUserDetail, DeleteUser } from "@/api/role";
import UserView from "./UserView.vue";
import UserEdit from "./UserEdit.vue";

const queryItems = [
  { label: "用户姓名", value: "u_name", type: "input" },
  {
    label: "用户所属分公司",
    value: "u_bc_id",
    type: "select",
    selectOption: "CompanyList",
    defaultValue: "",
  },
];
const columns = [
  {
    title: "用户ID",
    dataIndex: "u_id",
  },
  {
    title: "用户姓名",
    dataIndex: "u_name",
  },
  {
    title: "角色名称",
    dataIndex: "r_name",
  },
  {
    title: "所属分公司",
    dataIndex: "u_bc_name",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "230px",
    scopedSlots: { customRender: "action" },
  },
];
const orderParam = ["u_name", "r_name", "u_bc_id", "pageNo", "pageSize"];
export default {
  name: "SiteList",
  components: {
    STable,
    SSearch,
    UserView,
    UserEdit,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      // 查询参数
      queryParam: {},
      // 编辑，查看
      title: "",
      childPage: "",
      visible: false,
      confirmLoading: false,
      //用户信息
      userdata: {},
      // 删除
      visibleDel: false,
      confirmDelLoading: false,
      UserName: "",
      isShowBtns: [],
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
    };
  },
  created() {},
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },

  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetUsers(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        return res.data;
      });
    },
    fetchUsers(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true);
    },
    openSubpage(page, name, record) {
      //获取用户详情
      if (page === "edit" || page === "view") {
        let params = {
          u_id: record.u_id,
        };
        GetUserDetail(params).then((res) => {
          res.data.u_id = record.u_id;
          this.userdata = res.data;

          this.visible = true;
          this.childPage = page;
          this.title = "权限管理-用户权限" + name;
        });
      } else {
        this.visible = true;
        this.childPage = page;
        this.title = "权限管理-用户权限" + name;
      }
    },
    onClose() {
      this.visible = false;
      this.$refs.table.refresh(true);
      this.userdata = {};
    },
    // 删除
    handleDelete(record) {
      this.visibleDel = true;
      this.userdata.u_id = record.u_id;
      this.UserName = record.u_name;
    },
    deleteOk() {
      //删除用户
      let params = {
        u_id: this.userdata.u_id,
      };
      DeleteUser(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(this.UserName + res.data);
        } else {
          this.$message.error(this.UserName + res.data);
        }
      });
      this.visibleDel = false;
      //刷新列表
      this.$refs.table.refresh(true);
      this.userdata = {};
    },
    deleteCancel() {
      this.visibleDel = false;
      //刷新列表
      this.$refs.table.refresh(true);
      this.userdata = {};
    },
    // 刷新
    refresh() {
      this.$refs.table.refresh(true);
    },
  },
};
</script>
<style lang="less" scoped>
.text-btn + .text-btn {
  margin-left: 16px;
}
.danger {
  color: #ff4d4f;
}
.left {
  text-align: right;
}
</style>
