<template>
  <div>
    <div class="role-title">
      <div>{{ "用户姓名：" + userdata.u_name }}</div>
      <div>{{ "用户手机号：" + userdata.u_phone }}</div>
      <div>{{ "用户角色：" + userdata.r_name }}</div>
      <div>{{ "用户所属分公司：" + userdata.u_bcname }}</div>
    </div>
    <div class="role-title"></div>
    <div class="destri-cont">
      <div class="page-title">功能权限</div>
      <div class="page-cont">
        <div class="item-title">分公司数据可见范围</div>
        <a-row>
          <a-col :span="4" v-for="(child, j) in pages[0].children" :key="j">
            {{ child.bc_name }}
          </a-col>
        </a-row>

        <div class="item-title">系统功能使用范围</div>
        <a-row>
          <a-col :span="4" v-for="(child, j) in pages[1].children" :key="j">
            {{ child.name }}
          </a-col>
        </a-row>
      </div>

      <div class="dest-bottom" style="margin-top: 20px">
        <a-button @click="viewCancel()">返回</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import Page from "@/utils/page";
import { GetCompany } from "@/api/role";

export default {
  name: "RoleView",
  props: {
    userdata: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      pages: [
        {
          name: "分公司数据可见范围",
          children: [],
        },
        {
          name: "系统功能使用范围",
          children: Page,
        },
      ],
    };
  },
  created() {
    console.log(this.userdata);
    //获取所有分公司信息
    GetCompany().then((res) => {
      //根据用户进行渲染
      if (this.userdata.bc_id == "all") {
        this.pages[0].children = res.data;
      } else {
        let arr = [];
        for (let j = 0; j < res.data.length; j++) {
          if (this.userdata.bc_id.indexOf(res.data[j].bc_id.toString()) != -1) {
            arr.push(res.data[j]);
          }
        }
        this.pages[0].children = arr;
      }
    });
    if (this.userdata.bc_id != "all") {
      this.userdata.bc_id = this.userdata.bc_id.split(",");
    }
    this.userdata.r_right = this.userdata.r_right.split(",");
    //功能判断
    let pageArr = [];
    for (let i = 0; i < Page.length; i++) {
      for (let k = 0; k < Page[i].children.length; k++) {
        if (this.userdata.r_right.indexOf(Page[i].children[k].id) != -1) {
          pageArr.push(Page[i].children[k]);
        }
      }
    }
    this.pages[1].children = pageArr;
  },
  methods: {
    // 取消
    viewCancel() {
      this.$emit("onClose");
    },
  },
};
</script>
<style lang="less" scoped>
.destri-cont {
  padding: 24px;
  background-color: #fff;
}
.dest-bottom {
  text-align: right;
}
.role-title {
  font-weight: 500;
  font-size: 14px;
  color: #333;
  margin-bottom: 24px;
}
.page-title {
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-bottom: 24px;
}
.page-cont + .page-cont {
  margin-top: 10px;
}
.ant-col {
  margin-bottom: 20px;
}
.item-title {
  font-weight: 600;
  margin-bottom: 12px;
}
</style>
