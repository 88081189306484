<template>
  <div>
    <!-- <div class="role-title">{{ '用户姓名：稀土' }}</div> -->

    <a-row class="search-expand user-add">
      <a-col :span="8" v-for="item in Items" :key="item.value">
        <div>{{ item.label }}</div>
        <!-- 输入 -->
        <a-input
          v-show="item.type === 'input'"
          v-model="form[item.value]"
          class="search-expand-value"
          :placeholder="
            (item.type === 'input' ? '请输入' : '请选择') + item.label
          "
        />
        <!-- 选择 -->
        <a-select
          v-show="item.type === 'select'"
          v-model="form[item.value]"
          :placeholder="
            (item.type === 'input' ? '请输入' : '请选择') + item.label
          "
          class="search-expand-value"
          default-value="0"
        >
          <a-select-option
            v-for="opt in filters[item.selectOption]"
            :key="opt.r_id"
            :value="opt.r_id"
            >{{ opt.r_name }}</a-select-option
          >
        </a-select>
        <a-select
          v-show="item.type === 'selectCompanies'"
          v-model="form[item.value]"
          :placeholder="
            (item.type === 'input' ? '请输入' : '请选择') + item.label
          "
          class="search-expand-value"
          default-value="0"
        >
          <a-select-option
            v-for="opt in filters[item.selectOption]"
            :key="opt.bc_id"
            :value="opt.bc_id"
            >{{ opt.bc_name }}</a-select-option
          >
        </a-select>
      </a-col>
      <!-- 按钮 -->
      <a-col :span="8">
        <a-button
          type="primary"
          class="search-expand-button"
          @click="generatePwd()"
          >生成密码</a-button
        >
      </a-col>
    </a-row>

    <div class="destri-cont">
      <div class="page-title">
        <span>功能权限</span>
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
          >全选</a-checkbox
        >
      </div>
      <a-checkbox-group
        v-model="checkedList"
        @change="onChangeCheck"
        style="width: 100%"
      >
        <div class="page-cont">
          <div class="item-title">分公司数据可见范围</div>
          <a-row>
            <a-col :span="6" v-for="(child, j) in companylist" :key="j">
              <a-checkbox :value="child.bc_id.toString()">{{
                child.bc_name
              }}</a-checkbox>
            </a-col>
          </a-row>
        </div>
      </a-checkbox-group>
      <div class="dest-bottom" style="margin-top: 20px">
        <a-button @click="viewCancel()">返回</a-button>
        <a-button @click="editSubmit()" type="primary">确认</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { GetCompany, GetRoles, AddUser, EditUser } from "@/api/role";
import md5 from "md5";
import { GetCompanies } from "@/api/device";

const editParam = [
  "u_id",
  "u_name",
  "u_pass",
  "r_id",
  "u_phone",
  "bc_id",
  "u_bc_id",
];
const addParam = ["u_name", "u_pass", "r_id", "u_phone", "bc_id", "u_bc_id"];

export default {
  name: "UserEdit",
  props: {
    userdata: {
      type: Object,
      default: {},
    },
    childPage: {
      type: String,
    },
  },
  data() {
    return {
      companylist: [],
      subPages: [],
      // 全选
      checkAll: false,
      indeterminate: false,
      checkedList: [],

      filters: {
        RoleList: [
          // { label: "超级管理员", value: 'admin' },
        ],
        GetCompanies: [],
      },
      Items: [
        { label: "用户姓名", value: "u_name", type: "input" },
        { label: "手机号", value: "u_phone", type: "input" },
        {
          label: "角色",
          value: "r_id",
          type: "select",
          selectOption: "RoleList",
        },
        {
          label: "分公司",
          value: "u_bc_id",
          type: "selectCompanies",
          selectOption: "GetCompanies",
        },
        { label: "密码", value: "u_pass", type: "input" },
      ],
      form: {},
    };
  },
  created() {
    // console.log(" this.form", this.form);
    //获取所有分公司信息
    GetCompany().then((res) => {
      this.companylist = res.data;
      //将所有分公司信息记录
      this.companylist.forEach((el) => {
        this.subPages = this.subPages.concat(el);
      });
      //form表单初始化
      if (this.childPage === "add") {
        this.form = {};
      } else if (this.childPage === "edit") {
        this.$set(this.form, "u_id", this.userdata.u_id);
        this.$set(this.form, "u_name", this.userdata.u_name);
        this.$set(this.form, "u_phone", this.userdata.u_phone);
        this.$set(this.form, "r_id", this.userdata.r_id);
        this.$set(this.form, "u_bc_id", this.userdata.u_bc_id);

        if (this.userdata.bc_id == "all") {
          this.indeterminate = false;
          this.checkAll = true;
          let list = [];
          this.subPages.forEach((el) => {
            list.push(el.bc_id.toString());
          });
          this.checkedList = list;
        } else {
          let bc_id = this.userdata.bc_id.substr(
            0,
            this.userdata.bc_id.length - 1
          );
          this.checkedList = bc_id.split(",");
        }
      }
    });
    //获取所有角色信息
    const orderParam = [
      "r_id",
      "r_name",
      "r_describe",
      "r_bc_id",
      "r_belong_systems",
      "pageNo",
      "pageSize",
    ];
    // const param = Object.assign({});
    let pageArr = {
      pageNo: 1,
      pageSize: 100,
    };
    const param = Object.assign({}, pageArr);
    const requestParameters = this.$order(param, orderParam);
    const params = {
      ...requestParameters,
      verify: this.$verify(requestParameters, orderParam),
    };
    GetRoles(params).then((res) => {
      this.filters.RoleList = res.data.data;
      // console.log(res.data.data);
    });
    // 获取分公司信息
    GetCompanies().then((res) => {
      // console.log("GetCompanies", res.data);
      this.filters.GetCompanies = res.data;
    });
  },
  mounted() {},
  methods: {
    // 全选
    onCheckAllChange(e) {
      this.indeterminate = false;
      this.checkAll = e.target.checked;
      if (e.target.checked) {
        let list = [];
        this.subPages.forEach((el) => {
          list.push(el.bc_id.toString());
        });
        this.checkedList = list;
      } else {
        this.checkedList = [];
      }
    },
    onChangeCheck(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.subPages.length;
      this.checkAll = checkedList.length === this.subPages.length;
    },
    generatePwd() {
      this.$set(this.form, "u_pass", "abc12345");
    },
    // 确认
    editSubmit() {
      let param = this.form;
      console.log("this.form", this.form);
      if (this.childPage === "add") {
        // //添加用户
        param.u_pass = md5(param.u_pass);
        param.bc_id = this.checkedList.join(",");
        // param.u_bc_id=
        const params = {
          ...param,
          verify: this.$verify(param, addParam),
        };
        AddUser(params).then((res) => {
          if (res.code == "200") {
            this.$message.success(res.data);
          } else {
            this.$message.error(res.data);
          }
        });
        //刷新页面
      } else if (this.childPage === "edit") {
        //修改用户
        if (
          param.u_pass == undefined ||
          param.u_pass == null ||
          param.u_pass == ""
        ) {
          param.u_pass = "";
        } else {
          param.u_pass = md5(param.u_pass);
        }
        param.bc_id = this.checkedList.join(",");
        const params = {
          ...param,
          verify: this.$verify(param, editParam),
        };
        EditUser(params).then((res) => {
          if (res.code == "200") {
            this.$message.success(res.data);
          } else {
            this.$message.error(res.data);
          }
        });
      }

      this.$emit("onClose");
    },
    // 取消
    viewCancel() {
      this.$emit("onClose");
    },
  },
};
</script>
<style lang="less" scoped>
.destri-cont {
  padding: 24px;
  background-color: #fff;
}
.dest-bottom {
  text-align: right;
  button {
    margin-left: 16px;
  }
}
.role-title {
  font-weight: 500;
  font-size: 14px;
  color: #333;
  margin-bottom: 24px;
}
.page-title {
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}
.page-cont + .page-cont {
  margin-top: 16px;
}
.ant-col {
  margin-bottom: 24px;
}
.item-title {
  font-weight: 600;
  margin-bottom: 12px;
}
.user-add {
  padding: 20px 24px 0 24px;
  margin-bottom: 16px;
  background-color: #fff;
}
</style>
